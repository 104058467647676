<template>
  <div class="c-header">
    <div class="c-header__content">
      <div class="c-header__content-logo" @click="goToHome()">
        <img class="c-header__content-logo-img" src="../assets/images/logo.svg"/>
      </div>
      <!-- <div class="c-header__content-settlement">
        <p class="c-header__content-settlement-title"><span class="c-header__content-settlement-title-bold">Settlement</span> Price</p>
        <button type="button" name="button" class="c-header__content-settlement-view">View</button>
        <p><span class="c-header__content-settlement-subtitle">{{date}} {{time}} (GMT+8)</span></p>
      </div> -->
      <div class="c-header__content-currency">
        <div class="c-header__content-currency-container" v-for="(dt,index) in spotPriceData" :key="index">
          <div class="c-header__content-currency-container-coin">
            <img class="c-header__content-currency-container-coin-icon" :src="dt.icon"/>
            <div class="c-header__content-currency-container-coin-name">
              {{getCoinFromPair(dt.symbol)}}
            </div>
          </div>
          <div class="c-header__content-currency-container-amount" :class="{'c-header__content-currency-container-amount-no-border':index==spotPriceData.length-1}">
            {{dt.spot}}
          </div>
        </div>
      </div>
      <div class="c-header__content-menu" v-if="!token">
        <button class="c-header__content-menu-signin" @click="signIn" v-if="screenWidth > 428">Sign In</button>
        <button class="c-header__content-menu-signin" @click="signInMobile" v-else>Sign In</button>
        <button class="c-header__content-menu-register" @click="registerAccount">Register</button>
        <!-- <div class="c-header__content-menu-box" v-click-outside="hideDropdown">
          <div class="c-header__content-menu-box-language" @click="showDropdown()">
            <img class="c-header__content-menu-box-language-icon-language" src="@/assets/images/language.svg"/>
            <img class="c-header__content-menu-box-language-icon-dropdown" :src="!showLanguageDropdown ? require('@/assets/images/dropdown-down-icon.svg') : require('@/assets/images/dropdown-up-icon.svg')"/>
          </div>
          <div class="c-header__content-menu-box-dropdown" v-show="showLanguageDropdown">
            <div class="c-header__content-menu-box-dropdown-list" @click ="selectValue('en')">
              English
            </div>
            <div class="c-header__content-menu-box-dropdown-list" @click ="selectValue('cn')">
              中文简体
            </div>
            <div class="c-header__content-menu-box-dropdown-list" @click ="selectValue('kr')">
              한국어
            </div>
          </div>
        </div> -->
      </div>
      <div class="c-header__content-menu" v-else>
        <button class="c-header__content-menu-account" @click="myAccount">My Account</button>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
export default {
  name: 'Header',
  data() {
    return {
      showLanguageDropdown: false,
      dataLanguageDropdown:'',
      configJson: require('../../config.json'),
      date:'',
      time:'',
      utcDate:'',
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      allowanceCoin: [
        'BTC-SP$',
        'ETH-SP$',
        'EOS-SP$',
      ]
    }
  },
  mounted() {
    // BUAT HEADER ALPHA
    // this.setDateTime();
    //
  },
  methods: {
    showDropdown(){
      this.showLanguageDropdown= !this.showLanguageDropdown;
    },
    selectValue(item){
      this.hideDropdown();
      this.dataLanguageDropdown = item
    },
    hideDropdown(){
      this.showLanguageDropdown = false;
    },
    registerAccount() {
      // this.redirectUrl(this.configJson.desktop_url+"/signup")
      this.$router.push('/signup').catch(() => {})
      this.$eventHub.$emit('show-hide-risk', true);
    },
    myAccount() {
      window.location = '/dashboard/#/checker'
    },
    signInMobile(){
      // this.redirectUrl(this.configJson.desktop_url+"/signin")
      this.redirectMobileUrl('/')
      // this.$router.push('/signin').catch(() => {})
    },
    signIn(){
      // this.redirectUrl(this.configJson.desktop_url+"/signin")
      if(this.screenWidth > this.screenHeight && this.screenHeight <= 428) {
        this.signInMobile()
      } else {
        this.$router.push('/signin').catch(() => {})
      }
    },
    goToHome() {
      this.$eventHub.$emit('pass-route-name', 'home');
      this.$router.push('/').catch(()=>{});
    },
    getCoinFromPair:function(pair){
      return pair.split('-')[0]
    },
    filterSpotData:function(){

    }

    // BUAT HEADER ALPHA
    // setDateTime(){
    //   let today = new Date()
    //   this.date = today.getDate() + '-' + (today.getMonth()+1) + '-' + today.getFullYear()
    //   this.time = today.toLocaleString([], { hour: '2-digit', minute: '2-digit' });
    // }
    //
  },
  computed: {
    spotPriceData() {
      return this.$store.getters.getPriceWs
    },
    token:function(){
      return this.$store.getters.token;
    },
  },
  watch: {

  },
  directives: {
    clickOutside: vClickOutside.directive
  },
}
</script>
