<template>
  <div id="app" v-show="loaded">
    <div v-if="!hideHeader">
      <Header></Header>
      <Menu></Menu>
      <Alert></Alert>
      <AlertGettingStarted></AlertGettingStarted>
      <RiskWarning v-if="showModalRisk"></RiskWarning>
      <OnBoardingProcess v-if="showModalOnBoarding"></OnBoardingProcess>
      <Cookie :showCookie="showCookies"></Cookie>
      <TermsModal v-if="showTermsModal"></TermsModal>
      <!-- <Windsor v-if="showWindsorComponent"></Windsor> -->
    </div>
    <router-view/>
  </div>
</template>

<script>
import $ from 'jquery'
import { wsTick } from '../utils/websocket.js';
import RiskWarning from '@/components/RiskWarning.vue'
import OnBoardingProcess from '@/components/OnBoardingProcess.vue'
import Cookie from '@/components/CookieSetting.vue'
import Header from '@/components/Header.vue'
import Menu from '@/components/Menu.vue'
import Alert from '@/components/Alert.vue'
import AlertGettingStarted from '@/components/AlertGettingStarted.vue'
import TermsModal from '@/components/TermsModal.vue'
// import Windsor from '@/components/Windsor.vue'

export default {
  name: 'Home',
  components: {
    RiskWarning,
    OnBoardingProcess,
    Cookie,
    Header,
    Menu,
    Alert,
    AlertGettingStarted,
    TermsModal,
    // Windsor
  },
  data() {
    return {
      socketTick: wsTick,
      socketData:[],
      pairData:[],
      fetchedDictionary: false,
      hideHeader: false,
      showModalOnBoarding: false,
      showTermsModal:false,
      loaded:false,
      showWindsor: false
    }
  },
  created() {
    this.$eventHub.$on('show-hide-risk', (val) => {
      this.showHideRisk(val)
      this.setBodyOverflow(val)
    })
    this.$eventHub.$on('show-quick-guide', this.showQuickGuide)
    this.$eventHub.$on('hide-quick-guide', this.hideQuickGuide)
    this.$eventHub.$on('show-terms', this.showTerms)
    this.$eventHub.$on('hide-terms', this.hideTerms)
  },
  mounted:function(){
    // let _this = this
    this.getSystem()
    this.fetchPairList()
    this.websocketTickChanges()
    this.setBodyOverflow(this.showModalRisk)
    this.checkLogout()
    if(!this.fetchedDictionary){
      this.fetchDictionary()
    }
    this.loaded = true;
    // $(document).ready(function(){
    //   _this.updateViewMeta()
    // })
  },
  methods: {
    showQuickGuide:function(){
      this.showModalOnBoarding = true;
    },
    hideQuickGuide:function(){
      this.showModalOnBoarding = false;
    },
    getSystem: function() {
      this.$http.get(this.createApiUrlChestNut('system')).then(response =>
      {
        this.$store.commit('platformStatus',response.body.isPlatformLocked);
        this.$store.commit('setMaxUploadSize',response.body.fileMaxSize);
      },err => {
        this.showMessageError(err.body.message)
        if (err.status == 0) {
          this.showMessageError('Too many requests received, please try again shortly.',this.translate('error'))
        }
      })
    },
    fetchDictionary:function(){
      var dictionary = this.getDictionary()
      this.$store.commit('setDictionary',dictionary)
      this.fetchedDictionary = true
    },
    updateViewMeta:function(){
      let width = $(window).width()
      if (width >= 768){
        $("meta[name=viewport]").attr('content', 'content=width=device-width, initial-scale=1.0')
      }
      else{
        let zoom = width/1366
        $("meta[name=viewport]").attr('content', 'width=1366, initial-scale='+zoom)
      }
    },
    showHideRisk(val) {
      this.$store.commit('setShowDisclaimer',val)
      if(val == false){
        if (this.$route.name == 'Signup') {
          return false
        }
        this.showCookiePopup(true);
      }
    },
    showCookiePopup:function(val){
      this.$store.commit('setShowCookies',val)
    },
    setBodyOverflow:function(value){
      if(value == true) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'initial'
      }
    },
    websocketTickChanges: function() {
      let _this = this;
      this.socketTick.on('tick_change', function(message) {
        _this.socketData = message
      })
    },
    fetchPairList: function(){
      this.$http.get(this.createApiUrlChestNut('trades/pairs')).then(response =>{
        this.pairData = response.body
      },err => {
        console.log(err)
      });
    },
    getCoinFromPair:function(pair){
      return pair.split('-')[0]
    },
    checkLogout() {
      let url_string = new URL(window.location.href);
      let logout_param = url_string.searchParams.get("logout");
      if (logout_param == 'true') {
        this.$store.commit('doLogin',{token:''})
      }
    },
    showTerms:function(){
      this.showTermsModal = true;
    },
    hideTerms:function(){
      this.showTermsModal = false;
    },
  },
  computed:{
    showWindsorComponent(){
      let routeName = this.$route.name
      if(routeName == "Signup") {
        return true
      } else {
        return false
      }
    },
    showModalRisk: function(){
      return this.$store.getters.showDisclaimer
    },
    showCookies: function(){
      return this.$store.getters.showCookies
    },
    spotData:function(){
      let temp = []
      this.socketData.forEach((sock) => {
        for(let i = 0;i < this.pairData.length;i++){
          if(sock.symbol == this.pairData[i].pair){
            if(this.pairData[i].active){
              try {
                sock.icon = require('./assets/images/'+this.getCoinFromPair(sock.symbol).toLowerCase()+'.svg')
              } catch (e) {
                sock.icon = require('./assets/images/coin-placeholder.svg')
              }
              if (this.pairData[i].display) {
                temp.push(sock);
              }
            }
            break;
          }
        }
      })
      return temp;
    }
  },
  watch: {
    spotData(val) {
      this.$store.commit('setPriceWs',val)
    },
    $route (to){
      if (to.name == 'JumioSuccess' || to.name == 'JumioFailed') {
        this.hideHeader = true
      } else {
        this.hideHeader = false
      }
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/style';
</style>
