<template>
  <div id="captcha">
      <div id="wait" class="show">
          <div class="loading">
              <div class="loading-dot"></div>
              <div class="loading-dot"></div>
              <div class="loading-dot"></div>
              <div class="loading-dot"></div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      sitekey:'19fe8e680c7f61ba6e3c2652a9f24e05',
      geetestObj:null,
      invalidCaptcha:false
    }
  },
  // beforeDestroy(){
  //   this.$eventHub.$off('show-ReloadPage-top');
  // },
  methods: {
    execute () {
      this.geetestObj.verify()
    },
    reset () {
      this.render()
      this.geetestObj.reset()
    },
    render () {
      let _this = this;
      var handler = function (captchaObj) {
        _this.geetestObj = captchaObj
        _this.geetestObj.appendTo('#captcha');
        _this.geetestObj.onSuccess(function(){
          _this.$emit('verify', _this.geetestObj.getValidate())
        })
      };
      this.$http.get(this.createApiUrlChestNut('system/geetest-challenge')).then(response => {
        var challenge,offline,new_captcha;
        if(response.data){
          challenge = response.data.challenge;
          offline = !response.data.success;
          new_captcha= response.data.new_captcha;
        }else{
          challenge = '';
          offline = '';
          new_captcha= '';
        }
        window.initGeetest({
          // 以下 4 个配置参数为必须，不能缺少
          gt: _this.sitekey,
          challenge: challenge,
          offline: offline, // 表示用户后台检测极验服务器是否宕机
          new_captcha: new_captcha, // 用于宕机时表示是新验证码的宕机

          product: "bind", // 产品形式，包括：float，popup, custom
          width: "100%",
          lang: 'en',
          next_width: '278px',
          bg_color: 'gray',
          https: false,
          api_server: "apiv6.geetest.com"
          //http://docs.geetest.com/install/client/web-front/
        }, handler);
        this.invalidCaptcha = true
      }, err => {
        // err = {'body':{
        //             "code" : "InvalidRequestGeetestChallenge",
        //             "message": "Invalid Invalid captcha"
        //           }};
        if(err.body.code == 'InvalidRequestGeetestChallenge'){
          // this.showReloadPageMessage('Invalid captcha. ', true)
          this.invalidCaptcha = true
          this.$eventHub.$emit('invalidCaptchaStatus', this.invalidCaptcha)
        }
        // }else{
          // this.showMessageError(err, false , err.body.message)
        // }
        // this.showMessageError(err, false , err.status)
      })
    }
  },
  mounted () {
    this.render()
  }
}
</script>
