<template>
  <div class="c-alert" @click="closeAlert" :class="{'c-alert_show': alertTop}">
    <div class="c-alert__box" :class="{'c-alert__box_show': alertShowed}">
      <img class="c-alert__box-img" src="@/assets/images/alert-checklist.svg"/>
      <div class="c-alert__box-wrapper">
        <div class="c-alert__box-title">
          <p class="c-alert__box-title-text c-alert__box-title-text_bold">Thank you for your query and interest in Sparrow’s</p>
          <p class="c-alert__box-title-text c-alert__box-title-text_bold">digital asset wealth management solutions.</p>
        </div>
        <div class="c-alert__box-desc">
          <p class="c-alert__box-desc-text">Our team will be in touch with you shortly.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      alertShowed: false,
      alertTop: false
    }
  },
  created() {
    this.$eventHub.$on('show-alert-getting-started', (val) => {
      this.alertShowed = val
      this.alertTop = val
    })
  },
  methods: {
    closeAlert() {
      this.alertShowed = false
      setTimeout(function(){
        this.alertTop = false
      }, 500)
    }
  },
  watch: {
    alertShowed(val) {
      if(val == true) {
        setTimeout(() => {
          this.closeAlert()
        }, 5000)
      }
    }
  }
}
</script>
