<template>
  <div class="c-seen">
    <h3 class="c-seen__title">As Seen On</h3>
    <div class="c-seen__container" @mouseleave = "resetActive">
      <div class="c-seen__item" @mouseover="setActive('fintech')">
        <a href = "https://fintechnews.sg/" target = "_blank">
          <img src="@/assets/images/fintech-active.svg" alt="Fintech active" class="c-seen__item-image" v-if = "active=='fintech'">
          <img src="@/assets/images/fintech-inactive.svg" alt="Fintech inactive" class="c-seen__item-image" v-else>
        </a>
      </div>
      <div class="c-seen__item" @mouseover="setActive('forbes')">
        <a href = "https://forbesasiacustom.com/" target = "_blank">
          <img src="@/assets/images/forbes-active.svg" alt="Forbes active" class="c-seen__item-image" v-if = "active=='forbes'">
          <img src="@/assets/images/forbes-inactive.svg" alt="Forbes inactive" class="c-seen__item-image" v-else>
        </a>
      </div>
      <div class="c-seen__item" @mouseover="setActive('techinasia')">
        <a href = "https://www.techinasia.com/" target = "_blank">
          <img src="@/assets/images/techinasia-active.svg" alt="Techinasia active" class="c-seen__item-image" v-if = "active=='techinasia'">
          <img src="@/assets/images/techinasia-inactive.svg" alt="Techinasia inactive" class="c-seen__item-image" v-else>
        </a>
      </div>
      <div class="c-seen__item" @mouseover="setActive('theblock')">
        <a href = "https://www.theblockcrypto.com/" target = "_blank">
          <img src="@/assets/images/theblock-active.svg" alt="Theblock active" class="c-seen__item-image" v-if = "active=='theblock'">
          <img src="@/assets/images/theblock-inactive.svg" alt="Theblock inactive" class="c-seen__item-image" v-else>
        </a>
      </div>
      <div class="c-seen__item" @mouseover="setActive('bitcoin')">
        <a href = "https://bitcoinexchangeguide.com/" target = "_blank">
          <img src="@/assets/images/bitcoin-active.svg" alt="Bitcoin active" class="c-seen__item-image" v-if = "active=='bitcoin'">
          <img src="@/assets/images/bitcoin-inactive.svg" alt="Bitcoin inactive" class="c-seen__item-image" v-else>
        </a>
      </div>
      <div class="c-seen__item" @mouseover="setActive('techcrunch')">
        <a href = "https://techcrunch.com/" target = "_blank">
          <img src="@/assets/images/techcrunch-active.svg" alt="Techcrunch active" class="c-seen__item-image" v-if = "active=='techcrunch'">
          <img src="@/assets/images/techcrunch-inactive.svg" alt="Techcrunch inactive" class="c-seen__item-image" v-else>
        </a>
      </div>
      <div class="c-seen__item" @mouseover="setActive('business')">
        <a href = "https://www.sbr.com.sg/" target = "_blank">
          <img src="@/assets/images/business-active.svg" alt="Business active" class="c-seen__item-image" v-if = "active=='business'">
          <img src="@/assets/images/business-inactive.svg" alt="Business inactive" class="c-seen__item-image" v-else>
        </a>
      </div>
      <div class="c-seen__item" @mouseover="setActive('yahoo')">
        <a href = "https://finance.yahoo.com/" target = "_blank">
          <img src="@/assets/images/yahoo-active.svg" alt="Yahoo active" class="c-seen__item-image" v-if = "active=='yahoo'">
          <img src="@/assets/images/yahoo-inactive.svg" alt="Yahoo inactive" class="c-seen__item-image" v-else>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'AsSeenOn',
    data() {
      return {
        active:'',
      }
    },
    methods: {
      setActive(element) {
        this.active = element;
      },
      resetActive(){
        this.active = '';
      }
    }
  }

</script>
