<template>
  <div class="c-banner">
    <div class="c-banner__container">
      <div class="c-banner__container_box c-banner__container_box-home">
        Bespoke Digital <br />Asset Solutions
        <div class="c-banner__container_box-text">
          To achieve your growth objectives
        </div>
        <a @click='routerRedirect("/institutional/wealth")' class="c-banner__container_box-button">Get started > </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
