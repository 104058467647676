<template>
 <div class="c-footer" v-if="screenWidth > 800">
    <!-- <div class="c-footer" v-if="screenWidth > 0"> -->
   <div class="c-footer__body">
     <div class="c-footer__body-menu">
       <div class="c-footer__body-menu-list" v-for="(dt,index) in menuList" :key="index">
         <div class="c-footer__body-menu-list-title">
           {{dt.title}}
          <div class="c-footer__body-menu-list-yellow-border"></div>
          </div>
         <div class="c-footer__body-menu-list-submenu" v-for="(detail,idx) in dt.menu" :key="idx">
           <a @click='routerRedirect(detail.link)'>{{detail.text}}</a>
         </div>
       </div>
     </div>
     <div class="c-footer__body-contactus c-footer__body-contactus-new">
        <div class="c-footer__body-copyright">
          <p class="c-footer__body-copyright-text">2022  © Sparrow. All rights reserved</p>
        </div>
       <div class="c-footer__body-contactus-image c-footer__body-contactus-image-new">
         <div class="c-footer__body-contactus-image-list" v-for="(dt,index) in footerImgList" :key="index">
           <div class="c-footer__body-contactus-image-list-qrcode" v-if="index == 7 && weChatQrCode == true" v-click-outside="closeQr">
            <div class="c-footer__body-contactus-image-list-qrcode-arrow"></div>
            <img class="c-footer__body-contactus-image-list-qrcode-wechat" src="@/assets/images/qrcode-wechat.png">
           </div>
           <img class="c-footer__body-contactus-image-list-detail" :src="pressedIndex == index ? dt.imgPressed : hoveredIndex == index ? dt.imgHovered : dt.img " @mouseover="hoverImage(index)" @mouseleave="hoverImage(null)" @mousedown="pressImage(index)" @mouseup="pressImage(null)" @click="clickImage(dt.link, index)"/>
         </div>
       </div>
     </div>
     <!-- hoveredIndex == index ? dt.imgHovered : dt.img -->
     <!-- <div class="c-footer__body-copyright">
       <p class="c-footer__body-copyright-text">2022  © Sparrow. All rights reserved</p>
     </div> -->
   </div>
 </div>
 <div class="c-footer" v-else>
   <div class="c-footer__body">
     <div class="c-footer__body-menu">
       <div class="c-footer__body-menu-list" v-for="(dt,index) in menuList" :key="index">
         <div class="c-footer__body-menu-list-title" :class="{'c-footer__body-menu-list-title_inactive': dt.subMenuStatus == false}" @click="openSubMenu(index)">
           {{dt.title}}
          <div class="c-footer__body-menu-list-yellow-border"></div>
          </div>
         <div class="c-footer__body-menu-list-submenu" :class="{'c-footer__body-menu-list-submenu_active': dt.subMenuStatus == true}" v-for="(detail,idx) in dt.menu" :key="idx">
           <a @click='routerRedirect(detail.link)'>{{detail.text}}</a>
         </div>
       </div>
     </div>
     <div class="c-footer__body-contactus c-footer__body-contactus-new">
       <div class="c-footer__body-copyright c-footer__body-copyright-new">
          <p class="c-footer__body-copyright-text">2022  © Sparrow. All rights reserved</p>
       </div>
       <div class="c-footer__body-contactus-image c-footer__body-contactus-image-new">
         <div class="c-footer__body-contactus-image-list" v-for="(dt,index) in footerImgList" :key="index">
           <div class="c-footer__body-contactus-image-list-qrcode" v-if="index == 7 && weChatQrCode == true" v-click-outside="closeQr">
            <div class="c-footer__body-contactus-image-list-qrcode-arrow"></div>
            <img class="c-footer__body-contactus-image-list-qrcode-wechat" src="@/assets/images/qrcode-wechat.png">
           </div>
           <img class="c-footer__body-contactus-image-list-detail" :src="pressedIndex == index ? dt.imgPressed : hoveredIndex == index ? dt.imgHovered : dt.img " @mouseover="hoverImage(index)" @mouseleave="hoverImage(null)" @mousedown="pressImage(index)" @mouseup="pressImage(null)" @click="clickImage(dt.link, index)"/>
         </div>
       </div>
     </div>
     <!-- hoveredIndex == index ? dt.imgHovered : dt.img -->
   </div>
 </div>
</template>

<script>
  import vClickOutside from 'v-click-outside'
  export default {
    name: 'Footer',
    directives: {
      clickOutside: vClickOutside.directive
    },
    data() {
      return {
        menuList: [],
        footerImgList: [],
        hoveredIndex: null,
        pressedIndex: null,
        weChatQrCode: false,
        screenWidth: window.innerWidth
      }
    },
    mounted() {
      this.getMenuList()
      this.getScreenWidth()
      this.checkIpad()
    },
    methods: {
      getMenuList() {
        let hardCodeMenu = [
          {id: 1, title: 'About Sparrow', subMenuStatus: false, menu: [
            {
              text: 'Who We Are',
              link: "/about/who-we-are"
            },
            {
              text: 'Meet The Team',
              link: "/people-at-sparrow/team"
            },
            {
              text: 'Our Partners',
              link: "/about/partners"
            },
            {
              text: 'Why Sparrow',
              link: "/about/why-sparrow"
            },
            {
              text: 'Join Sparrow',
              link: "/people-at-sparrow/join-sparrow"
            }
          ]},
          {id: 2, title: 'Digital Asset Products', subMenuStatus: false, menu: [
            {
              text: 'Personal',
              link: "/consumer/individual"
            },
            // {
            //   text: 'Product Packages',
            //   // link: "/consumer/individual"
            // },
            // {
            //   text: 'Institutional',
            //   link: "/institutional/institution"
            // },
            {
              text: 'Bespoke Solutions',
              link: "/institutional/wealth"
            },
            {
              text: 'Get Started',
              link: "/institutional/getting-started"
            }
          ]},
          {id: 3, title: 'Resources & Support', subMenuStatus: false, menu: [
            {
              text: 'Sparrow Blog',
              link: "/library/sparrow-blog"
            },
            {
              text: 'News & Media Room',
              link: "/library/news-media-room"
            },
            {
              text: 'FAQ',
              link: "/support/faq"
            },
            {
              text: 'Sparrow Cares',
              link: "/support/sparrowcares"
            }
          ]},
          {id: 4, title: 'Legal & Compliance', subMenuStatus: false, menu: [
            {
              text: 'Disclaimer & Risk Warning',
              link: "/legal/disclaimers-and-risk-warning"
            },
            {
              text: 'Regulation',
              link: "/legal/regulation"
            },
            {
              text: 'User Protection Guidance',
              link: "/legal/user-protection-guidance"
            },
            {
              text: 'User Agreement',
              link: "/legal/user-agreement"
            },
            {
              text: 'Privacy Policies',
              link: "/legal/privacy-policies"
            },
            // {
            //   text: 'Security',
            //   link: "/legal/security"
            // },
            {
              text: 'Technology',
              link: "/legal/technology"
            }
          ]}
        ]

        let hardCodeFooterImg = [
          {
            img: require('../assets/images/telegram.svg'),
            imgHovered: require('../assets/images/telegram-hover.svg'),
            imgPressed: require('../assets/images/telegram-pressed.svg'),
            link: 'https://t.me/SparrowExchange'
          },
          {
            img: require('../assets/images/twitter.svg'),
            imgHovered: require('../assets/images/twitter-hover.svg'),
            imgPressed: require('../assets/images/twitter-pressed.svg'),
            link: 'https://twitter.com/SparrowExchange'
          },
          {
            img: require('../assets/images/facebook.svg'),
            imgHovered: require('../assets/images/facebook-hover.svg'),
            imgPressed: require('../assets/images/facebook-pressed.svg'),
            link: 'https://www.facebook.com/SparrowExchange '
          },
          {
            img: require('../assets/images/bitcoin.svg'),
            imgHovered: require('../assets/images/bitcoin-hover.svg'),
            imgPressed: require('../assets/images/bitcoin-pressed.svg'),
            link: 'https://bitcointalk.org/index.php?topic=5146551.0'
          },
          {
            img: require('../assets/images/reddit.svg'),
            imgHovered: require('../assets/images/reddit-hover.svg'),
            imgPressed: require('../assets/images/reddit-pressed.svg'),
            link: 'https://www.reddit.com/r/SparrowExchange/'
          },
          {
            img: require('../assets/images/medium.svg'),
            imgHovered: require('../assets/images/medium-hover.svg'),
            imgPressed: require('../assets/images/medium-pressed.svg'),
            link: 'https://medium.com/sparrowexchange'
          },
          {
            img: require('../assets/images/youtube.svg'),
            imgHovered: require('../assets/images/youtube-hover.svg'),
            imgPressed: require('../assets/images/youtube-pressed.svg'),
            link: 'https://www.youtube.com/channel/UCXW1AX7vdgkcIxNaZJFRFwg'
          },
          // {
          //   img: require('../assets/images/kakaotalk.svg'),
          //   imgHovered: require('../assets/images/kakaotalk-hover.svg'),
          //   imgPressed: require('../assets/images/kakaotalk-pressed.svg'),
          //   link: ''
          // },
          {
            img: require('../assets/images/wechat.svg'),
            imgHovered: require('../assets/images/wechat-hover.svg'),
            imgPressed: require('../assets/images/wechat-pressed.svg'),
            link: ''
          }
        ]

        this.menuList = hardCodeMenu
        this.footerImgList = hardCodeFooterImg
      },
      getScreenWidth() {
        let _this = this
        window.onresize = window.onload = function() {
          _this.screenWidth = this.innerWidth;
        }
      },
      hoverImage(idx) {
        this.hoveredIndex = idx
      },
      pressImage(idx) {
        this.pressedIndex = idx
      },
      clickImage(link, index) {
        if(index == 7) {
          this.weChatQrCode = true
        } else {
          this.openLink(link)
        }
      },
      closeQr() {
        this.weChatQrCode = false
      },
      resetSubMenuStatus() {
        this.menuList.forEach(dt => {
          dt.subMenuStatus = false
        })
      },
      openSubMenu(index) {
        if(this.screenWidth <= 428) {
          if(this.menuList[index].subMenuStatus == true) {
            this.menuList[index].subMenuStatus = false
          } else {
            this.resetSubMenuStatus()
            this.menuList[index].subMenuStatus = true
          }
        }
      },
      checkIpad() {
        if(this.screenWidth > 621 && this.screenWidth < 1279) {
          this.menuList.forEach(dt => {
            dt.subMenuStatus = true
          })
        }
      }
    }
  }
</script>
