<template>
  <div class="c-cookie" :class = "{'c-cookie_show': showCookie}">
    <div class="c-cookie__container">
      <div class="c-cookie__text-container">
        <p class="c-cookie__text">We use cookies on our website to offer you relevant contents by remembering your preferences, visits and activities. By clicking <span class = "c-cookie__text_bold">“Accept”</span>, you consent to the use of all the cookies.</p>
      </div>
      <div class="c-cookie__button-container">
        <div class="c-cookie__button" @click="goToPrivacyPolicy">Privacy Policy</div>
        <div class="c-cookie__button c-cookie__button_blue" @click="hideCookie">Accept Cookies</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookiePopup',
  props: {
    showCookie: Boolean
  },
  methods:{
    hideCookie:function(){
      this.$store.commit('setShowCookies',false)
    },
    goToPrivacyPolicy:function(){
      this.$router.push('/legal/privacy-policies').catch(() => {})
      this.$store.commit('setShowCookies',false)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
