import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// eventhub
Vue.prototype.$eventHub = new Vue(); // Global event bus

// vue resource
import VueResource from 'vue-resource'
Vue.use(VueResource)
Vue.http.headers.common['Authorization'] = 'Bearer ' + store.getters.token
Vue.http.interceptors.push((request, next) => {
  if (request.method.toLowerCase() === 'get') {
    request.url += (request.url.indexOf('?') > 0 ? '&' : '?') + `t=${new Date().getTime()}`
    next()
  }
})

// VUE MOMENT
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

Vue.use(VueMoment, {
    moment,
})

// Mixin For Global Function
import Mixin from './mixin'
Vue.mixin(Mixin)

import Select2 from 'v-select2-component';
Vue.component('Select2', Select2);

// ELEMENT UI - COMPONENT AND TEMPLATE
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

Vue.config.productionTip = false

Vue.filter('translate', function (value) {
  return Mixin.methods.translate(value);
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
