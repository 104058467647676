<template>
  <div class="c-menu__container-search" :class="{'c-menu__container-search_full-flex': isSearching}">
    <input type="text" ref="search" v-model="search" v-if = "isSearching" class="c-menu__container-search-field" placeholder="Search..." v-click-outside="closeSearch">
    <img class="c-menu__container-search-icon" src="../assets/images/white-magnifier.svg" @click = "openSearch" v-if = "!isSearching"/>
    <img class="c-menu__container-search-icon" src="../assets/images/white-x.svg" @click = "closeSearch" v-else/>
    <div class = "c-menu__container-search-result" v-if="isSearching" v-click-outside="closeSearchMobile">
      <div class = "c-menu__container-search-link" v-for = "(route,idx) in matchingRoutes" :key="idx" @click="moveTo(route)">{{route.name}}</div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
export default {
  data () {
    return {
      search: '',
      routes:this.$router.options.routes,
      isSearching: false,
      screenWidth: window.innerWidth
    }
  },
  methods:{
    moveTo: function(route){
      this.isSearching = false
      this.search = '';
      if(route.name != this.$route.name){
        this.$router.push(route).catch(() => {})
      }
    },
    openSearch:function(){
      let _this = this;
      this.isSearching = true;
      this.$nextTick(()=>{
        _this.$refs.search.focus()
      });
    },
    closeSearch:function(){
      if(this.screenWidth > 428) {
        setTimeout(() => {
          this.isSearching = false
        }, 100)
      }
    },
    closeSearchMobile:function(){
      if(this.screenWidth <= 428) {
        setTimeout(() => {
          this.isSearching = false
        }, 100)
      }
    }
  },
  computed:{
    matchingRoutes: function(){
      let temp = []
      let query = this.search.toLowerCase()
      if(query != ''){
        let expression = new RegExp('^.*?'+query+'.*?$')
        this.routes.forEach(function(route){
          if(expression.test(route.name.toLowerCase())&&route.searchable){
            temp.push(route);
          }
        })
      }
      return temp
    }
  },
  watch: {
    isSearching(val) {
      this.$eventHub.$emit('status-search', val);
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
}
</script>
