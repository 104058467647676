<template>
  <div class="c-sparrowblog">
    <div class="c-sparrowblog__title">
      <p class="c-sparrowblog__title-text">Sparrow Blog</p>
    </div>
    <div class="c-sparrowblog__banner-wrapper">
      <div class="c-sparrowblog__banner">
        <div class="c-sparrowblog__banner-list" v-for="(dt,index) in blogList" :key="index" :class="{'c-sparrowblog__banner-list_first': index == 0, 'c-sparrowblog__banner-list_middle': index == 1, 'c-sparrowblog__banner-list_last': index == 2}" @click="openLink(dt.link)">
          <div class="c-sparrowblog__banner-list-img">
            <img class="c-sparrowblog__banner-list-img-detail c-sparrowblog__banner-list-img-new" :src="dt.img">
          </div>
          <div class="c-sparrowblog__banner-list-description c-sparrowblog__banner-list-description-new">
            <div class="c-sparrowblog__banner-list-description-title c-sparrowblog__banner-list-description-title-new">{{dt.title}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="c-sparrowblog__seeall">
      <a href="https://blog.sparrowexchange.com/" target="_blank" class="c-sparrowblog__seeall-text">See all</a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SparrowBlog',
    data() {
      return {
        blogList: []
      }
    },

    mounted() {
      this.getBlogListData()
    },
    methods: {
      getBlogListData() {
        let hardCode = [
          // {id:1, title: 'Why should financial institutions engage a digital assets partner?', img: require('@/assets/images/blog/sparrow-blog-asset.png'), link: 'https://blog.sparrowexchange.com/why-should-financial-institutions-engage-a-digital-assets-partner/'},
          // {id:2, title: 'Singapore stands tall among the world’s most crypto-forward countries', img: require('@/assets/images/blog/sparrow-blog-singapore-stands.png'), link: 'https://blog.sparrowexchange.com/singapore-cryptocurrency-forward-country/'},
          // {id:3, title: 'Secure your cryptocurrency trading experience with these tips', img: require('@/assets/images/blog/secure-your-crypto.svg'), link: 'https://blog.sparrowexchange.com/secure-your-cryptocurrency-trading-experience-with-these-tips/'}
          {id:1, title: 'Compliance at the forefront of cryptocurrency', img: require('@/assets/images/blog/sparrow-blog-cryptocurrency.svg'), link: 'https://blog.sparrowexchange.com/compliance-at-the-forefront-of-cryptocurrency/'},
          {id:2, title: 'Why is a Major Payment Institution (MPI) licence a key evaluation criterion when selecting a digital assets partner?', img: require('@/assets/images/blog/sparrow-blog-mpi.svg'), link: 'https://blog.sparrowexchange.com/why-is-a-major-payment-institution-mpi-licence-a-key-evaluation-criterion-when-selecting-a-digital-assets-partner/'},
          {id:3, title: 'How can cryptocurrency drive innovation and efficiency within the financial industry?', img: require('@/assets/images/blog/sparrow-blog-cryptocurrency-drive.svg'), link: 'https://blog.sparrowexchange.com/how-can-cryptocurrency-drive-innovation-and-efficiency-within-the-financial-industry/'}
        ]
        this.blogList = hardCode
      }
    }
  }

</script>
