<template>
  <div class="c-terms-modal">
    <div class="c-terms-modal__bg" @click="rejectTerms"></div>
    <div class="c-terms-modal__content">
      <div class="c-terms-modal__head">
        <h2 class="c-terms-modal__title">Terms of Use <span class="c-terms-modal__title-regular">&</span> Privacy and Data Protection Policy</h2>
        <p class="c-terms-modal__title-desc">We have just updated our terms of use & privacy and data protection policy, in order to continue using Sparrow’s services, you will need to read through and accept the updated terms.</p>
      </div>
      <div class="c-terms-modal__body">
        <div class="c-terms-modal__nav">
          <p class="c-terms-modal__nav-item" :class="{'c-terms-modal__nav-item_active':isTerms}" @click="showTerms">Terms of Use</p>
          <p class="c-terms-modal__nav-item" :class="{'c-terms-modal__nav-item_active':!isTerms}" @click="showPrivacy">Privacy Policy</p>
        </div>
        <div class="c-terms-modal__text" @scroll = "scrollHandler">
          <div class = "c-terms-modal__terms">
            <Terms :styleModal="true"></Terms>
          </div>
          <div class="c-terms-modal__marker"></div>
          <PrivacyPolicy :styleModal="true"></PrivacyPolicy>
          <div class="c-terms-modal__button-container">
            <button class="c-terms-modal__accept" @click="acceptTerms">Accept & Continue</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Terms from '@/components/legal/UserAgreementContent.vue'
import PrivacyPolicy from '@/components/legal/PrivacyPoliciesContent.vue'
export default {
  name: 'TermsModal',
  components: {
    Terms,
    PrivacyPolicy
  },
  props: {
    styleCss: Boolean,
    styleSpecific: String
  },
  data() {
    return {
      isTerms:true
    }
  },
  methods:{
    showTerms:function(){
      $('.c-terms-modal__text').animate({
        scrollTop: 0
      }, 500);
      this.isTerms = true;
    },
    showPrivacy:function(){
      $('.c-terms-modal__text').animate({
        scrollTop: $('.c-terms-modal__terms').height()
      }, 500);
      this.isTerms = false;
    },
    scrollToTop:function(){
      $('.c-terms-modal__text').scrollTop(0);
    },
    acceptTerms:function(){
      let data = {
        tncAccepted: true
      }

      if (this.$route.name == 'Signup') {
        this.$eventHub.$emit('hide-terms');
      } else {
        this.$http.put(this.createApiUrlChestNut('me'), data)
        .then(() => {
            this.$eventHub.$emit('hide-terms');
            window.location = 'dashboard/#/checker'
          },
          err => {
            this.showMessageError(err);
          }
        );
      }
    },
    rejectTerms: function(){
      if (this.$route.name == 'Signup') {
        this.$eventHub.$emit('hide-terms');
        this.$eventHub.$emit('reject-terms');
      } else {
        this.$store.commit('doLogin',{token:''})
        this.$eventHub.$emit('hide-terms');
        this.$router.push('/').catch(()=>{});
      }
    },
    scrollHandler: function() {
      this.isTerms = $('.c-terms-modal__marker').position().top>$('.c-terms-modal__text').position().top;
    }
  }
}
</script>
