import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist/dist/umd'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  key: 'alphaNew',
  storage: window.localStorage
})

const store = new Vuex.Store({
  state: {
    showDisclaimer: true,
    showCookies: false,
    spotPrice: [],
    token: '',
    dictionary: '',
    lang: 'en',
    _platformStatus: false,
    maxUploadSize:null,
    profileData: [],
    announcementId: null,
  },
  getters: {
    token: state => {
      return state.token
    },
    showDisclaimer: state => {
      return state.showDisclaimer
    },
    showCookies: state => {
      return state.showCookies
    },
    getPriceWs: state => {
      return state.spotPrice
    },
    getLang: state => {
      return state.lang
    },
    getDictionary: state => {
      return state.dictionary
    },
    platformStatus: state => {
      return state._platformStatus;
    },
    getMaxUploadSize: state => {
      return state.maxUploadSize;
    },
    getProfileData: state => {
      return state.profileData
    },
    getAnnouncementId: state =>{
      return state.announcementId
    }
  },
  mutations: {
    doLogin: function (state, payload) {
      Vue.http.headers.common['Authorization'] = 'Bearer ' + payload.token
      state.token = payload.token
    },
    setShowDisclaimer: function(state, param) {
      state.showDisclaimer = param
    },
    setShowCookies: function(state, param) {
      state.showCookies = param
    },
    setPriceWs: function(state, param) {
      state.spotPrice = param
    },
    setDictionary: function(state,data) {
      state.dictionary = data
    },
    changeLang: function(state,lang){
      state.lang = lang
      let temp = "English"
      if(lang == "zh-CN"){
        temp = "SimplifiedChinese";
      }else if(lang == "en"){
        temp = "English";
      } else if(lang == "ko") {
        temp = "Korean"
      }
      window.changeHTMLLang(lang);
      window.localStorage.setItem('lang', temp); //set localstorage lang
    },
    platformStatus: function(state,param) {
      state._platformStatus = param;
    },
    setMaxUploadSize: function(state,maxUploadSize){
      state.maxUploadSize = maxUploadSize
    },
    setProfileData:function(state,param){
      state.profileData = param;
    },
    setAnnouncementId:function(state, param){
      state.announcementId = param;
    }
  },
  plugins: [vuexLocal.plugin]
})

export default store
