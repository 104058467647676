<template>
  <div class="c-alert" @click="closeAlert" :class="{'c-alert_show': alertTop, 'c-alert_blue': iconInfo}">
    <div class="c-alert__box" :class="{'c-alert__box_show': alertShowed}">
      <img class="c-alert__box-img" src="@/assets/images/alert-checklist.svg" v-if="iconInfo"/>
      <img class="c-alert__box-img" src="@/assets/images/alert-cross.svg" v-else/>
      <div class="c-alert__box-wrapper">
        <div class="c-alert__box-title">
          <p class="c-alert__box-title-text c-alert__box-title-text_bold">{{message.title}}</p>
        </div>
        <div class="c-alert__box-desc">
          <p class="c-alert__box-desc-text">{{message.body}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      alertShowed: false,
      alertTop: false,
      message: {
        title: '',
        body: ''
      },
      iconInfo: true
    }
  },
  created() {
    this.$eventHub.$on('show-alert', (val, text, icon) => {
      this.alertShowed = val
      this.alertTop = val
      if(text) {
        this.message.title = text.title
        this.message.body = text.body
      }
      this.iconInfo = icon
    })
  },
  methods: {
    closeAlert() {
      this.alertShowed = false
      setTimeout(function(){
        this.alertTop = false
      }, 500)
    }
  },
  watch: {
    alertShowed(val) {
      if(val == true) {
        setTimeout(() => {
          this.closeAlert()
        }, 5000)
      }
    }
  }
}
</script>
