<template>
  <div class="c-legal-description" :class="{'c-legal-description_modal': styleModal == true}">
    <h2 class="c-legal-description__title" v-if="styleModal == false">
      Sparrow Privacy and Data Protection Policy
    </h2>
    <h2 class="c-legal-description__title_modal-text" v-else>
      Privacy and Data Protection Policy
    </h2>
    <!-- <h2>
      as your digital asset wealth management partner
    </h2> -->
    <p class="c-legal-description__content c-legal-description__content-term-of-use-margin-bottom-20">
      This Privacy Policy describes how the Sparrow Group (which includes Sparrow Holdings Pte. Ltd. and its subsidiaries, affiliates, associated companies and jointly controlled entities) (collectively <span class="c-legal-description__content_bold">“Sparrow”</span>, <span class="c-legal-description__content_bold">“We”</span> or <span class="c-legal-description__content_bold">“Our”</span>) collects, processes, manages and safeguards personal data through the use of Sparrow’s website and mobile application (<span class="c-legal-description__content_bold">“Sparrow Platform”</span>), as well as products, features and other services operated by Sparrow (collectively, <span class="c-legal-description__content_bold">“Services”</span>).
    </p>
    <p class="c-legal-description__content c-legal-description__content-term-of-use-margin-bottom-20">
      Sparrow operates the Sparrow Platform which is an interactive online peer-to-peer options trading platform for cryptocurrency enthusiasts that facilitates the buying and selling of Sparrow Options.
    </p>
    <p class="c-legal-description__content c-legal-description__content-term-of-use-margin-bottom-20">

      Sparrow (through its subsidiary Sparrow Tech Private Limited) is licensed by the Monetary Authority of Singapore (<span class="c-legal-description__content_bold">“MAS”</span>) as a Major Payment Institution Licence under the Payment Services Act No. 2 of 2019 to provide Digital Payment Token (<span class="c-legal-description__content_bold">“DPT”</span>) services. Hence Sparrow adheres strictly to the requirements stipulated by MAS for DPT services and our compliance and legal processes, security and technological platforms and personal data protection policies are maintained according to MAS requirements and applicable laws. This includes requiring all Users to undergo identity verification such as KYC measures and AML/CFT screening. We are a responsible member of the trading community, and we will work with regulators and traders to provide innovative digital asset products and solutions globally.
    </p>
    <p class="c-legal-description__content c-legal-description__content-term-of-use-margin-bottom-20">
      This Privacy Policy applies to all users of the Sparrow Platform (<span class="c-legal-description__content_bold">“Users”</span>) and all personal data collected by Sparrow, operating in the capacity of a data principal or a data intermediary, from all (i) visitors to the Sparrow Platform; (ii) persons who register their emails with Sparrow; and (iii) Users. For avoidance of doubt, this Privacy Policy does not apply to anonymised data, or information which cannot be used to identify any individual person.
    </p>
    <p class="c-legal-description__content c-legal-description__content-term-of-use-margin-bottom-20">
      The use of the Sparrow Platform entails various types of personal data being provided for various purposes. Under the Personal Data Protection Act (No. 26 of 2012) (the <span class="c-legal-description__content_bold">“PDPA”</span>), <span class="c-legal-description__content_bold">“personal data”</span> means data, whether true or not, about an individual who can be identified from that data or from that data and other information to which the organisation has or is likely to have access. The PDPA governs and protects the collection, use, disclosure and care of personal data. This may include the User’s name, nationality, telephone number, bank and credit card details, personal interests, email address, pictures, identification card numbers, biometric data, race, date of birth, marital status, religion, health information, vehicle and insurance information, employment information and financial information. Organisations will have to comply with the PDPA as well as the common law and other relevant laws that are applied in the specific industry that they belong to, when handling personal data in their possession.
    </p>
    <p class="c-legal-description__content c-legal-description__content-term-of-use-margin-bottom-20">
      By accessing the Sparrow Platform and creating a user account with Sparrow, Users agree and consent to Sparrow collecting, using, disclosing and sharing such personal data across all Sparrow entities, and disclosing such personal data to relevant third-party service providers in accordance with the Privacy Policy. Sparrow may also combine the collected personal data with other personal data in Sparrow’s possession. If Users use the Services across the various Sparrow entities, Sparrow will link personal data collected across the various Sparrow entities to facilitate the User’s use of the Services and for the purposes in paragraph 2.1 below.
    </p>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      1. COLLECTION OF PERSONAL DATA
    </p>
    <div class="c-legal-description__content_list_data">
      <div class="c-legal-description__content_list_data-number">1.1</div>
      <div class="c-legal-description__content_list_data-text">The Sparrow Platform may be browsed anonymously. However, Users will have to create a user account on the Sparrow Platform in order to utilise the Services. </div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">1.2</div>
      <div class="c-legal-description__content_list_data-text">Generally, Sparrow may collect Users' personal data in the following circumstances:
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(i)</div>
          <div class="c-legal-description__content_list-data">when the User creates an account on the Sparrow Platform;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(ii)</div>
          <div class="c-legal-description__content_list-data">when Sparrow implements additional measures to authenticate user identity;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(iii)</div>
          <div class="c-legal-description__content_list-data">when the User uses the Services;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(iv)</div>
          <div class="c-legal-description__content_list-data">when the User carries out transactions on the Sparrow Platform; and</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(v)</div>
          <div class="c-legal-description__content_list-data">when the User submits personal data to Sparrow for any other reason, including through any interactions whether directly through the Sparrow Platform or via social media.</div>
        </div>
      </div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">1.3</div>
      <div class="c-legal-description__content_list_data-text">Users authorise Sparrow to process and store such personal data for the purposes provided for in paragraph 2.1 below and if applicable, verify such credentials with the relevant social media network. Sparrow may also, where necessary, collect personal data of Users from credit agencies, law enforcement agencies, or government agencies and sources including publicly available information, referral programs or social media services, in accordance with the relevant authorisation procedures under Applicable Laws.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">1.4</div>
      <div class="c-legal-description__content_list_data-text">If Users provide Sparrow with any personal data relating to a third-party, by submitting such information to Sparrow, Users represent to Sparrow that they have obtained the consent of the third-party to provide Sparrow with such personal data which may be collected, used and disclosed in accordance with this Privacy Policy.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">1.5</div>
      <div class="c-legal-description__content_list_data-text">Users shall ensure that all personal data submitted to Sparrow is true, complete and accurate. Any omission or failure to do so, or any refusal to share required personal data in order to comply with legal requirements or contractual obligations, may prevent Sparrow from fulfilling the purposes stated in paragraph 2.1.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">1.6</div>
      <div class="c-legal-description__content_list_data-text">Users acknowledge that all personal data submitted to Sparrow is in compliance with all applicable laws relating to data protection including without limitation, the PDPA, the General Data Protection Regulation (EU) 2016/679 as well as the common law and other relevant laws (<span class="c-legal-description__content_bold">“Applicable Laws”</span>). If Users are in doubt as to their rights in relation to their personal data, they should consult their solicitors or other professional advisors.</div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      2.	USE OF PERSONAL DATA
    </p>
    <div class="c-legal-description__content_list_data">
      <div class="c-legal-description__content_list_data-number">2.1</div>
      <div class="c-legal-description__content_list_data-text">In general, Sparrow collects and uses personal data for the following purposes:
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(i)</div>
          <div class="c-legal-description__content_list-data">to process the User's application for an account on the Sparrow Platform;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(ii)</div>
          <div class="c-legal-description__content_list-data">to maintain the User's account;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(iii)</div>
          <div class="c-legal-description__content_list-data">to verify the User's identity;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(iv)</div>
          <div class="c-legal-description__content_list-data">to provide Users with the Services;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(v)</div>
          <div class="c-legal-description__content_list-data">to collect information in order to comply with and enforce know-your-customer policies and regulations, anti-money laundering and countering the financing of terrorism laws and regulations, or any other Applicable Laws;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(vi)</div>
          <div class="c-legal-description__content_list-data">to handle disputes and conduct and facilitate investigations and proceedings;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(vii)</div>
          <div class="c-legal-description__content_list-data">for Sparrow’s prudential and operational management (including risk management, audit, compliance, outsourcing of services, business and financial decision-making);</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(viii)</div>
          <div class="c-legal-description__content_list-data">to ensure or enhance network and information security;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(ix)</div>
          <div class="c-legal-description__content_list-data">to personalise User experience and respond to individual needs, including for testing, research, analysis and product development;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(x)</div>
          <div class="c-legal-description__content_list-data">to improve and receive feedback on the Services and the Sparrow Platform;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(xi)</div>
          <div class="c-legal-description__content_list-data">to improve customer service and provide support services;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(xii)</div>
          <div class="c-legal-description__content_list-data">to process interest in buying or creating Sparrow Options over cryptocurrencies; and</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(xiii)</div>
          <div class="c-legal-description__content_list-data">to send periodic emails, including but not limited to news, updates and related information on the Services, the Sparrow Platform or Sparrow.</div>
        </div>
      </div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">2.2</div>
      <div class="c-legal-description__content_list_data-text">The purposes listed in paragraph 2.1 above are not exhaustive and Sparrow may collect and/or use personal data for additional purposes which Users will be notified of, in accordance with the applicable terms and conditions. Further, these purposes may continue to apply even in situations where the relationship between Sparrow and the Users (for example, pursuant to a contract) has been terminated or altered in any way, for a reasonable period thereafter (including, where applicable, a period to enable Sparrow to enforce its rights under any contract with the Users).</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">2.3</div>
      <div class="c-legal-description__content_list_data-text">Sparrow may engage or use any service providers (including, but not limited to, KYC solution provider(s)) (<span class="c-legal-description__content_bold">“Sub-Processor”</span>) for the processing of personal data or permit any authorised third-party to process personal data. If Sparrow appoints a Sub-Processor, Sparrow will ensure that, prior to the processing taking place, there is a written contract in place between Sparrow and the Sub-Processor that specifies the Sub-Processor's processing activities and imposes on the Sub-Processor the same terms as those imposed on Sparrow in this Privacy Policy.</div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      3.	DISCLOSURE
    </p>
    <div class="c-legal-description__content_list_data">
      <div class="c-legal-description__content_list_data-number">3.1</div>
      <div class="c-legal-description__content_list_data-text">Sparrow takes care to protect and safeguard Users’ personal data. This includes measures to prevent personal data from getting lost or used or accessed in an unauthorised way. Sparrow limits access to User personal data to employees on a need-to-know basis. Those processing personal data will only do so in an authorised manner and are required to treat such information with confidentiality.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">3.2</div>
      <div class="c-legal-description__content_list_data-text">Sparrow also employs security measures to protect personal data, including:
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(i)</div>
          <div class="c-legal-description__content_list-data">all personal data collected on the Sparrow Platform shall be transmitted via Secure Socket Layer (SSL) technology and then encrypted into the gateway provider's database only to be accessible by those authorized with special access rights who are required to keep the personal data confidential; and</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(ii)</div>
          <div class="c-legal-description__content_list-data">all personal data shall be encrypted on the server.</div>
        </div>
      </div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">3.3</div>
      <div class="c-legal-description__content_list_data-text">Users should be aware, however, that no method of transmission over the internet or method of electronic storage is completely secure. While security cannot be guaranteed and any transmission remains at the User’s own risk, Sparrow strives to protect the security of the Users' information and is constantly reviewing and enhancing its information security measures.
      </div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">3.4</div>
      <div class="c-legal-description__content_list_data-text">However, at times, Users’ personal data will have to be accessed by, or shared with, persons who have a legitimate purpose for accessing it. For example, Sparrow may disclose personal data to third-party service providers or to representatives within Sparrow for the purposes such as those listed in paragraph 2.1, on the condition that the disclosed personal data is kept confidential and not further disclosed.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">3.5</div>
      <div class="c-legal-description__content_list_data-text">In particular, Sparrow may also disclose personal data for legal purposes, including but not limited to compliance, enforcement of legal rights and obligations and to obtain legal advice. Sparrow may also disclose personal data to a party authorised and consented to by the User.</div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      4.	COOKIES AND THIRD-PARTY WEBSITES
    </p>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">4.1</div>
      <div class="c-legal-description__content_list_data-text">Cookies are small files that a website or service provider transfers to computer hard drives through a web browser that enables the website or service provider's systems to recognize the browser and record certain information. Cookies may have unique identifiers, and reside, among other places, on the User’s computer or mobile device, in emails and on our web pages. Cookies may transmit personal data about Users and their use of the Services, such as browser type, search preferences, IP address, data relating to advertisements that have been displayed or that Users have clicked on, and the date and time of use. Cookies may be persistent or stored only during an individual session.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">4.2</div>
      <div class="c-legal-description__content_list_data-text">Sparrow uses cookies on the Sparrow Platform to record Users' usage patterns and trends and customize User experience. Sparrow may engage third-party service providers to assist in such purposes or allow third parties to use cookies on the Sparrow Platform to collect the same type of personal data for the same purposes Sparrow does for itself. Sparrow does not have access to or control over the cookies these third-parties use. However, such third-party service providers may not disclose personal data obtained in the course of their engagement to any other parties. Additionally, Sparrow may share non-personally identifiable personal data with third-parties.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">4.3</div>
      <div class="c-legal-description__content_list_data-text">Depending on the type of phone and operating software that the User is using, the User may be granted the option to disable the use of mobile identifiers. Users may also disable the processing of cookies on their web browsers but this may prevent Users from maximising the Services provided by Sparrow.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">4.4.</div>
      <div class="c-legal-description__content_list_data-text">Sparrow shall not be responsible for the collection, use, disclosure and care of personal data by any third-party websites linked to the Sparrow Platform.</div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      5. ACCESS TO AND CORRECTION OF PERSONAL DATA, WITHDRAWAL OF CONSENT
    </p>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">5.1</div>
      <div class="c-legal-description__content_list_data-text">In accordance with Applicable Laws, Users are entitled to:
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(i)</div>
          <div class="c-legal-description__content_list-data">ask about the processing of the User’s personal data, including to be provided with a copy of such personal data;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(ii)</div>
          <div class="c-legal-description__content_list-data">request the correction, update and/or (in some cases) deletion of the User’s personal data;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(iii)</div>
          <div class="c-legal-description__content_list-data">in some cases, request the restriction of the processing of the User’s personal data, or object to that processing;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(iv)</div>
          <div class="c-legal-description__content_list-data">withdraw consent to the processing of the User’s personal data (where Sparrow is processing the User’s personal data based on the User’s consent);</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(v)</div>
          <div class="c-legal-description__content_list-data">request receipt or transmission to another organisation, in a machine-readable form, of the User’s personal data that was provided to Sparrow where Sparrow is using the User’s personal data based on consent or performance of a contract; and</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">(vi)</div>
          <div class="c-legal-description__content_list-data">complain to the relevant data privacy authority if the User’s data privacy rights are violated, or if the User has suffered as a result of unlawful processing of the User’s personal data.</div>
        </div>
      </div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
        <div class="c-legal-description__content_list_data-number">5.2</div>
        <div class="c-legal-description__content_list_data-text">If the User wishes to make a request to exercise its rights as above, the User can contact Sparrow’s Data Protection Officer at <span class="c-legal-description__content-term-of-use-email" @click="mailTo('dpo@sparrowexchange.com')">dpo@sparrowexchange.com</span>. Sparrow will screen and verify all requests beforehand and may require the User to provide supporting information or documentation to corroborate the request. Once verified, Sparrow will give effect to the User’s request within the timelines prescribed by Applicable Laws. Alternatively, Users may also review, correct, amend or delete any part of their personal data by logging into their accounts via the Sparrow Platform.</div>
      </div>
      <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
        <div class="c-legal-description__content_list_data-number">5.3</div>
        <div class="c-legal-description__content_list_data-text">Please note that a reasonable fee may be charged for an access request. Sparrow shall inform the Users of the fees before processing the request.</div>
      </div>
      <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
        <div class="c-legal-description__content_list_data-number">5.4</div>
        <div class="c-legal-description__content_list_data-text">For the avoidance of doubt, where any deletion of personal data may result in loss of data integrity and auditable records, Sparrow may elect not to comply with such request if it conflicts with Sparrow’s obligation to comply with Applicable Laws or for the establishment, exercise or defence of legal claims.</div>
      </div>
      <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20 c-legal-description__content-term-of-use-margin-top-20">
        <div class="c-legal-description__content_list_data-number">5.5</div>
        <div class="c-legal-description__content_list_data-text">Where the User is given the option to share the User’s personal data with Sparrow, the User may always choose not to do so. If Sparrow has requested the User’s consent to processing and the User later chooses to withdraw it, Sparrow will respect that choice in accordance with its legal obligations. However, choosing not to share the User’s personal data with Sparrow or withdrawing consent to the use of it may prevent Sparrow from fulfilling the purposes stated in paragraph 2.1, or may result in the User not being able to make use of the Services. Even after the User has chosen to withdraw consent, Sparrow shall remain able to continue to process the User’s personal data to the extent required or otherwise permitted by Applicable Laws.</div>
      </div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      6. TRANSFER OF PERSONAL DATA OUTSIDE OF SINGAPORE
    </p>
    <div class="c-legal-description__content_list_data">
      <div class="c-legal-description__content_list_data-number">6.1</div>
      <div class="c-legal-description__content_list_data-text">Users consent to the transfer of their personal data to countries outside of Singapore. Where Sparrow transfers personal data out of Singapore, Sparrow shall comply with such legal and regulatory obligations in relation to the personal data, including having a lawful basis (such as Users’ consent or other basis under Applicable Laws) for transferring the personal data and putting appropriate safeguards in place to ensure an adequate level of protection for the personal data. Sparrow shall also take steps to ensure that Users' personal data continues to receive a standard of protection that is at least comparable to that provided under this Privacy Policy.</div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40 c-legal-description__content-max">
      7.	COMPLAINTS UNDER THE EUROPEAN GENERAL DATA PROTECTION REGULATIONS AS APPLICABLE TO PERSONAL DATA OF EU PERSONS
    </p>
    <div class="c-legal-description__content_list_data">
      <div class="c-legal-description__content_list_data-number">7.1</div>
      <div class="c-legal-description__content_list_data-text">Sparrow may collect personal data from individuals and institutions located within the European Economic Area, and Sparrow is responsible for ensuring that it uses such personal data in accordance with the General Data Protection Regulation (EU) 2016/679.</div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      8.	RETENTION OF PERSONAL DATA
    </p>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-bottom-20">
      <div class="c-legal-description__content_list_data-number">8.1.</div>
      <div class="c-legal-description__content_list_data-text">Sparrow may retain Users' personal data for as long as it is necessary to fulfil the purposes stated in paragraph 2.1, or as required or permitted by Applicable Laws.</div>
    </div>
    <div class="c-legal-description__content_list_data">
      <div class="c-legal-description__content_list_data-number">8.2.</div>
      <div class="c-legal-description__content_list_data-text">Sparrow shall cease to retain Users' personal data, or remove the means by which the data can be associated with the Users, as soon as it is reasonable to assume that such retention no longer serves the purposes stated in paragraph 2.1, and are no longer necessary for legal or business purposes.</div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      9.	CHANGES TO PRIVACY POLICY
    </p>
    <div class="c-legal-description__content_list_data">
      <div class="c-legal-description__content_list_data-number">9.1.</div>
      <div class="c-legal-description__content_list_data-text">Sparrow may modify, update or amend the terms in this Privacy Policy at any time. Such amendments shall be notified to Users through the Sparrow Platform and/or other appropriate means. The updated version will be posted on the Sparrow Platform. It is the User’s responsibility to review this Privacy Policy regularly. The User’s continued use of the Sparrow Platform or the Services, purchase of products from Sparrow, or continued communication or engagement with Sparrow following the modifications, updates or amendments to this Privacy Policy, whether or not reviewed by the User, shall constitute the User’s agreement to be bound by such amendments.</div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      10.	FEEDBACK
    </p>
    <div class="c-legal-description__content_list_data">
      <div class="c-legal-description__content_list_data-number">10.1</div>
      <div class="c-legal-description__content_list_data-text">Users may contact Sparrow at <span class="c-legal-description__content-term-of-use-email" @click="mailTo('dpo@sparrowexchange.com')">dpo@sparrowexchange.com</span> if there are any questions or feedback regarding the Privacy Policy.
      </div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      11.	EFFECT OF POLICY
    </p>
    <div class="c-legal-description__content_list_data">
      <div class="c-legal-description__content_list_data-number">11.1.</div>
      <div class="c-legal-description__content_list_data-text">This Privacy Policy applies in conjunction with and not in substitution of any other policies, notices, contractual clauses and consent clauses that apply in relation to the collection, use and disclosure of the User's personal data by Sparrow.
    </div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      12.	GOVERNING LAW AND JURISDICTION
    </p>
    <div class="c-legal-description__content_list_data">
      <div class="c-legal-description__content_list_data-number">12.1.</div>
      <div class="c-legal-description__content_list_data-text">The Privacy Policy shall be governed by and in accordance with the laws of Singapore.
      </div>
    </div>
    <div class="c-legal-description__content-term-of-use-margin-top-20 c-legal-description__content-term-of-use-margin-bottom-70" :class="{'c-legal-description__content-term-of-use-no-margin-bottom': styleModal}">
      <div class="c-legal-description__content-version">
        PP Version 2.0
      </div>
      <div class="c-legal-description__content-version">
        Last modified on 24 June 2022.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  props: {
    styleModal: Boolean,
  },
  data() {
    return {

    }
  },
}
</script>
