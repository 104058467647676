<template>
  <div class="c-onboarding">
    <div class="c-onboarding__mask"></div>
    <div class="c-onboarding__body">
      <div class="c-onboarding__body-modal">
        <div class="c-onboarding__body-modal-icon">
          <img src="@/assets/images/times.svg" alt="Success" class="c-onboarding__body-modal-icon-img" @click = "hideQuickGuide">
        </div>
        <div class="c-onboarding__body-modal-title">Onboarding Process</div>
        <div class="c-onboarding__body-modal-subtitle">A guide to a quick and fuss-free onboarding process with Sparrow</div>
        <div class="c-onboarding__body-modal-note">Note: Identification Document = ID = International Passport/National ID/Driver’s License</div>
        <div class="c-onboarding__body-modal-content">
          <div class="c-onboarding__body-modal-content-container">
            <div class="c-onboarding__body-modal-content-container-title">
              You must be over 18 to register for an account
            </div>
            <div class="c-onboarding__body-modal-content-container-description">
              Sparrow can only accept users above the age of 18. If you are under the age of 18, you will not pass identity verification.
            </div>
          </div>
          <div class="c-onboarding__body-modal-content-container">
            <div class="c-onboarding__body-modal-content-container-title">
              Submit a valid ID
            </div>
            <div class="c-onboarding__body-modal-content-container-description">
              Please submit a valid ID that is not expired. Also ensure that your ID includes a photograph of you on the document. If your ID is past its expiry date, you will not pass identity verification.
            </div>
          </div>
          <div class="c-onboarding__body-modal-content-container">
            <div class="c-onboarding__body-modal-content-container-title">
              Real-time ID and facial photo
            </div>
            <div class="c-onboarding__body-modal-content-container-description">
              Please take and capture a brand-new selfie. It should be a clear image of your entire face. Do not submit an image of your passport photo or an existing photo. The system will not accept the submission.
            </div>
          </div>
          <div class="c-onboarding__body-modal-content-container">
            <div class="c-onboarding__body-modal-content-container-title">
              Select the correct ID type
            </div>
            <div class="c-onboarding__body-modal-content-container-description">
              Select the correct document type that you want to use. For example, if you select a passport for your ID type, please make sure you capture a photo of your passport, and not your driver’s license.
            </div>
          </div>
          <div class="c-onboarding__body-modal-content-container">
            <div class="c-onboarding__body-modal-content-container-title">
              Capture the correct images as instructed
            </div>
            <div class="c-onboarding__body-modal-content-container-description">
              Carefully follow the instructions of what images are required. The instructions may ask for an image of the front only, or both the front and back, depending on the ID type. For passports, take a photo of the entire passport, and not just the identity information page. If you capture a cropped ID image, the system will not accept the submission.
            </div>
          </div>
          <div class="c-onboarding__body-modal-content-container">
            <div class="c-onboarding__body-modal-content-container-title">
              Submit an image that is legible, unaltered, undamaged, and free from glare
            </div>
            <div class="c-onboarding__body-modal-content-container-description">
              Please ensure that the image of the ID is clear. If an image is illegible, altered, or damaged, it will not be accepted for verification. Do not conceal any information or add any marks or annotations to the image. The images submitted must be unaltered. Another common reason for verification failure is glare from a hologram or plastic cover. Please ensure there is no glare in the images submitted.
            </div>
          </div>
          <div class="c-onboarding__body-modal-content-container">
            <div class="c-onboarding__body-modal-content-container-title">
              Submit IDs with Latin (English) characters for faster identity verifications
            </div>
            <div class="c-onboarding__body-modal-content-container-description">
              For the fastest identity verification, please use international passports as these documents support Latin characters.
            </div>
          </div>
          <div class="c-onboarding__body-modal-content-container">
            <div class="c-onboarding__body-modal-content-container-title">
              Send us a support request if your ID does not use Latin (English) characters
            </div>
            <div class="c-onboarding__body-modal-content-container-description">
              If your ID does not use Latin characters, after submitting your “selfie” and image of your ID, please submit your issue on the Sparrow Support site at:
              <a @click="mailTo('support@sparrowexchange.com')" class="c-onboarding__body-modal-content-container-link">https://support.sparrowexchange.com/</a><br>
              Please provide your full name in your native language to expedite the manual verification procedures.
            </div>
          </div>
          <div class="c-onboarding__body-modal-content-container">
            <div class="c-onboarding__body-modal-content-container-title">
              Enter the correct information as per your ID / Document
            </div>
            <div class="c-onboarding__body-modal-content-container-description">
              Please ensure that the information that you have entered is exactly the same as per your ID / Document that you have submitted. Any discrepancy will result in a longer verification process
            </div>
          </div>
          <div class="c-onboarding__body-modal-content-container">
            <div class="c-onboarding__body-modal-content-container-title">
              Submit Proof of Residential Address and Source of Wealth that are clear and free from alteration
            </div>
            <div class="c-onboarding__body-modal-content-container-description">
              Please submit the correct proof of residential address and source of wealth documents that are legible, clear and not altered in any way. Application will be immediately rejected if any of the documents were found to be tampered with.
            </div>
          </div>
          <div class="c-onboarding__body-modal-content-container">
            <div class="c-onboarding__body-modal-content-container-title">
              Sparrow’s commitment to compliance
            </div>
            <div class="c-onboarding__body-modal-content-container-description">
              We are committed to being a responsible digital asset platform and we comply with the rules and regulations of the Republic of Singapore. We seek your cooperation and understanding by helping us make Sparrow the best place to trade digital assets.
            </div>
          </div>
          <div class="c-onboarding__body-modal-content-container">
            <div class="c-onboarding__body-modal-content-container-description">
              If you encounter any issues with the onboarding process, please submit your query on our Sparrow Support site at: <a @click="mailTo('support@sparrowexchange.com')" class="c-onboarding__body-modal-content-container-link">https://support.sparrowexchange.com/</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods:{
    hideQuickGuide:function(){
      this.$eventHub.$emit('hide-quick-guide')
    }
  }
}
</script>
