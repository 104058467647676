import io from "socket.io-client";

// WEBSOCKET HOST
const host = require('../config.json').api_pricing + 'ws/';

// NAMESPACE FOR TICK
const ws_tick = function() {
  const ws_tick = host + 'tick';
  let tick_socket = io.connect(ws_tick, { transports: ['websocket'], forceNew: false });
  return tick_socket;
};

// OPEN WEBSOCKET CONNECTION
let wsTick = ws_tick();

export {
  wsTick
};
